<template>
  <sign-page
    title-text="岗位晋升预警"
    :request="request"
    :column-list="columns"
    table-size="large"
    :titleMenus="[]"
    @tableAction="tableAction"
    :tableActions="[]"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '@/api'

import {
  dealQueryData
} from '@/syslib/worker_job_title'

export default {
  methods: {
    tableAction (parm) {
      this.chooseData = parm.data
      this.modal = true
    }
  },
  computed: {
    columns () {
      return [{
        title: '员工编码',
        field: 'code',
        sort: true,
        fixed: 'left'
      },
      {
        title: '员工姓名',
        field: 'name',
        sort: true,
        fixed: 'left'
      },
      {
        title: '预警内容',
        field: 'wContent',
        fixed: 'left',
        width: 300,
        sort: true
      },
      {
        title: '适岗执业资格证',
        field: 'sgCertificateTitle',
        sort: true
      },
      {
        title: '适岗执业资格证获得时间',
        dataType: Date,
        sort: true,
        field: 'sgGetDate',
        render: (h, rowData) => {
          return h('div', rowData.sgGetDate ? rowData.sgGetDate.slice(0, 10) : '-')
        }
      },
      {
        title: '本岗累积工作年限',
        sort: true,
        dataType: Number,
        field: 'cJobTitleDiffYears'
      },
      {
        title: '当前岗位',
        sort: true,
        field: 'cJobTitleTitles'
      },
      {
        title: '当前岗位聘任起始时间',
        dataType: Date,
        sort: true,
        field: 'cJobTitleStartDates'
      }]
    }
  },
  data () {
    return {
      request: {
        get: async () => {
          let parm = {
            isWarning: 'w',
            maxDiffDay: 30
          }
          let data = await request.getQueue(parm)
          data = dealQueryData(data)
          let showData = []
          data.forEach(v => {
            v.workers.forEach((v2) => v2.wContent = '岗位即将可晋级:' + v.type + '-' + v.topLevel+ '-' + v.mLevel+ '-' + v.title)
            showData = showData.concat(v.workers)
          })
          showData.sort((a, b) => a.code - b.code)
          return showData
        }
      },
      modal: false,
      chooseData: null
    }
  }
}
</script>
